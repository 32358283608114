
import { defineComponent, ref } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ApiService from "@/core/services/ApiService";
import { ElForm } from "element-plus";
import { ElMessage } from "element-plus";

interface IEmployeeInput {
  name: string;
  email: string;
  position_id?: number | null;
  division_id?: number | null;
}

export default defineComponent({
  name: "add-employee-modal",
  props: ["divisionList", "positionList", "selectedEmployee"],
  data() {
    return {
      addEmployeeModalRef: "addEmployeeModal",
      formData: ref<IEmployeeInput>({
        name: "",
        email: "",
        position_id: null,
        division_id: null,
      }),
      formRef: "addEmployee",
      loading: ref<boolean>(false),
      rules: {
        name: [
          {
            required: true,
            message: "Member name is required",
            trigger: ["submit"],
          },
        ],
        email: [
          {
            required: true,
            message: "Member email is required",
            trigger: ["submit"],
          },
        ],
      },
    };
  },
  methods: {
    async addNewMember(newEmployee: IEmployeeInput) {
      try {
        const { data } = await ApiService.vueInstance.axios.post(
          "employee/v1/",
          newEmployee
        );
        if (data.response == 200) {
          hideModal(this.$refs[this.addEmployeeModalRef] as HTMLElement);
          this.loading = false;
          this.resetData();
          ElMessage.success(`Successfully add ${newEmployee.name} as employee`);
          this.$emit("addedNewMember");
        }
      } catch (error) {
        if (error instanceof Error) {
          // ✅ TypeScript knows err is Error
          ElMessage.error(error);
        } else {
          ElMessage.error("Unexpected error");
        }
      }
    },
    submit() {
      if (!this.$refs[this.formRef]) {
        return;
      }

      (this.$refs[this.formRef] as typeof ElForm).validate((valid) => {
        if (valid) {
          this.loading = true;
          const input = {
            name: this.formData?.name.trim(),
            email: this.formData?.email.trim(),
            division_id: this.formData.division_id,
            position_id: this.formData.position_id,
          };
          this.addNewMember(input);
        } else {
          Swal.fire({
            text: "Sorry, looks like there are some errors detected, please try again.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          return false;
        }
      });
    },
    resetData() {
      this.formData = {
        name: "",
        email: "",
        position_id: null,
        division_id: null,
      };
    },
  },
  watch: {
    selectedEmployee: function (newVal) {
      if (newVal) {
        this.formData = {
          name: newVal.name,
          email: newVal.email,
          position_id: newVal?.position?.id,
          division_id: newVal?.division?.id,
        };
      }
    },
  },
  setup() {
    return {};
  },
});
