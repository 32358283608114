
import { defineComponent, ref } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import addEmployeeModal from "@/components/modals/EmployeeModal/addEmployeeModal.vue";
import ApiService from "@/core/services/ApiService";
import { serverListInterface } from "@/models/member/IMember";
import { ElMessage } from "element-plus";

interface IDropdown {
  id: number;
  name: string;
}

interface IEmployee {
  id: number;
  email: string;
  name: string;
  position: {
    id: number;
    name: string;
  };
  division: {
    id: number;
    name: string;
  };
}

export default defineComponent({
  name: "member-listing",
  components: {
    Datatable,
    addEmployeeModal,
  },
  data() {
    return {
      checkedEmployee: ref<number[]>([]),
      headers: [
        {
          key: "checkbox",
          sortable: false,
        },
        {
          name: "Id",
          key: "id",
          sortable: true,
        },
        {
          name: "Name",
          key: "name",
          sortable: true,
        },
        {
          name: "Email",
          key: "email",
          sortable: true,
        },
        {
          name: "Position",
          key: "position",
          sortable: true,
        },
        {
          name: "Division",
          key: "division",
          sortable: false,
        },
        {
          key: "action",
          sortable: false,
        },
      ],
      search: "",
      employee: ref<Array<IEmployee>>([]),
      initialData: ref<Array<IEmployee>>([]),
      listServer: ref<serverListInterface[]>([]),
      memberType: ref<IDropdown[]>([]),
      divisions: ref<IDropdown[]>([]),
      positions: ref<IDropdown[]>([]),
      selectedEmployee: ref<IEmployee | null>(null),
    };
  },
  methods: {
    errorHandler(error) {
      if (error instanceof Error) {
        // ✅ TypeScript knows err is Error
        ElMessage.error(error.message);
      } else {
        ElMessage.error("Unexpected error");
      }
    },
    async editMember(employeeData) {
      this.selectedEmployee = employeeData;
    },
    async deleteSelectedEmployee() {
      try {
        const deletePromises = this.checkedEmployee.map((memberId) => {
          return ApiService.vueInstance.axios.delete(`employee/v1/${memberId}`);
        });
        await Promise.all(deletePromises);
        const newEmployeeList = this.employee.filter((employee) => {
          return !this.checkedEmployee.includes(employee.id);
        });
        this.employee.splice(
          0,
          newEmployeeList.length + this.checkedEmployee.length,
          ...newEmployeeList
        );
        this.checkedEmployee.length = 0;
      } catch (error) {
        this.errorHandler(error);
      }
    },
    async getEmployeeList() {
      // TODO: sort with right order
      try {
        const employeeList = await ApiService.vueInstance.axios.get(
          "employee/v1/list"
        );
        this.employee.splice(
          0,
          employeeList.data.data.length,
          ...employeeList.data.data
        );

        this.initialData.splice(0, this.employee.length, ...this.employee);
      } catch (error) {
        this.errorHandler(error);
      }
    },
    async getDivisionList() {
      try {
        const divisionList = await ApiService.vueInstance.axios.get(
          "employee-division/v1/list"
        );
        this.divisions.splice(
          0,
          divisionList.data.data.length,
          ...divisionList.data.data
        );
      } catch (error) {
        this.errorHandler(error);
      }
    },
    async getPositionList() {
      try {
        const positionList = await ApiService.vueInstance.axios.get(
          "employee-position/v1/list"
        );
        this.positions.splice(
          0,
          positionList.data.data.length,
          ...positionList.data.data
        );
      } catch (error) {
        this.errorHandler(error);
      }
    },
    searchMember() {
      this.employee.splice(0, this.employee.length, ...this.initialData);
      if (this.search !== "") {
        let results: Array<IEmployee> = [];
        for (let i = 0; i < this.employee.length; i++) {
          if (this.keyMatches(this.employee[i], this.search)) {
            results.push(this.employee[i]);
          }
        }
        this.employee.splice(0, this.employee.length, ...results);
      }
    },
    keyMatches(obj, value): boolean {
      for (let key in obj) {
        if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {
          if (
            obj[key].toString().toLowerCase().indexOf(value.toLowerCase()) != -1
          ) {
            return true;
          }
        }
      }
      return false;
    },
    initialFetch() {
      this.getEmployeeList();
      this.getDivisionList();
      this.getPositionList();
    },
  },
  mounted() {
    this.initialFetch();
  },
});
